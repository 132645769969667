import React from 'react';
import DefaultLayout from '../layouts/Default';
import Seo from '../components/global/Seo';
import styled from 'styled-components';
import { Link as LinkG } from 'gatsby';

export default function NotFoundPage() {
  return (
    <DefaultLayout>
      <Seo title='Η σελίδα δεν βρέθηκε' />

      <NotFoundSection>
        <div className='section-inner'>
          <h1>404</h1>
          <p>Η σελίδα δεν βρέθηκε</p>
          Επιστροφή στην <LinkG to='/'>Αρχική</LinkG>
        </div>
      </NotFoundSection>
    </DefaultLayout>
  );
}

const NotFoundSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
